function isMobile() {
    return $('body').hasClass('device-xs')
}

function isHandheld() {
    return $('body').hasClass('device-sm');
}

function isTablet() {
    return $('body').hasClass('device-md');
}

function isLaptop() {
    return $('body').hasClass('device-lg')
}

function isDesktop() {
    return $('body').hasClass('device-xl')
}

function slideToggleNavs(target,outTarget,event) {
    var checkClick = false;

    target.click(function(e){
        e.preventDefault();
        outTarget.slideDown(300);

        checkClick = true;

        $(document).bind('click.'+event, function (e) {
            if (!checkClick && $(e.target).closest(outTarget).length === 0) {
                outTarget.slideUp(300);
                $(document).unbind('click.'+event);
            }
            checkClick = false;
        });
    });
}

function tabsInit($selector) {
    var tabsNavA = $selector.find('.tabs-nav a'),
        tabContent = $selector.find('.tab-content');

    tabContent.find('.tab-pane').removeClass('active').removeClass('show');
    tabContent.find('.tab-pane').eq(0).addClass('active').addClass('show');

    tabsNavA.click(function (e) {
        e.preventDefault();

        var t = $(this),
            target = $(t.attr('href'));

        tabsNavA.removeClass('active');
        tabsNavA.parent().removeClass('active');
        t.addClass('active');
        t.parent().addClass('active');
        target.parent().find('.active').removeClass('show');

        setTimeout(function () {
            target.parent().find('.active').removeClass('active');
            target.addClass('active');
            setTimeout(function () {
                target.addClass('show');
            },200);
        },200);
    });
}
var CF = CF || {};

(function($){

    'use strict';

    CF.initialize = {
        init: function(){
            CF.widgets.init();
            CF.documentOnLoad.init();
            CF.documentOnResize.init();
        }
    };

    CF.widgets = {
        init: function(){
            CF.widgets.detectBrowsers();
            CF.widgets.initMap();
            CF.widgets.draggableMap($mapOuter);
        },
        detectBrowsers: function(){
            var user = detect.parse(navigator.userAgent);
            $h.addClass(user.browser.family);
            $h.addClass("v"+user.browser.version);
        },
        preLoadImages: function(){
            for(var i = 0; i < arguments.length; i++) {
                $("<img>").attr("src", arguments[i]);
            }
        },
        initMap: function () {

            function mapDotHover() {
                var index = $(this).index() + 1;
                $mapNavigation.find('ul>li').removeClass('active');
                $mapNavigation.find('[data-index="'+ index +'"]').addClass('active');
            }

            function mapDotLeave() {
                $mapNavigation.find('ul>li').removeClass('active');
            }

            function mapNavHover() {
                $mapNavigation.find('ul>li').removeClass('active');
                $mapDots.find('.map-dot-wrapper').removeClass('hover');
                $mapDots.find('.map-dot-wrapper').eq($(this).data('index') - 1).addClass('hover');
            }

            function mapNavLeave() {
                $mapNavigation.find('ul>li').removeClass('active');
                $mapDots.find('.map-dot-wrapper').removeClass('hover');
            }

            function centerDotMap(dot) {
                var maxRight = -$mapOuter.width() + $mapContainer.width();
                var maxBottom = -$mapOuter.height() + $mapContainer.height();

                var x = -(dot.position().left + 14 - $mapContainer.width() / 2),
                    y = -(dot.position().top + 14 - $mapContainer.height() / 2);

                x < maxRight ? x = maxRight : null;
                y < maxBottom ? y = maxBottom : null;

                x > 0 ? x = 0 : null;
                y > 0 ? y = 0 : null;

                $mapOuter.css({'transform' : 'translateX('+ x +'px) translateY('+ y +'px)'});
            }

            function mapDotClick() {

                var $t = $(this);
                centerDotMap($t);

                if($w.width() < 767) {
                    if($t.is('hover')) {
                        $t.removeClass('hover');
                    } else {
                        $mapDots.find('.map-dot-wrapper').removeClass('hover');
                        $t.addClass('hover');
                    }
                }

            }

            function mapNavClick() {

                var $d = $mapDots.find('.map-dot-wrapper').eq($(this).data('index') - 1);

                centerDotMap($d);

                $mapSidebar.removeClass('show');

            }

            $b.on('mouseenter', '.map-dot-wrapper', mapDotHover);
            $b.on('mouseleave', '.map-dot-wrapper', mapDotLeave);

            $b.on('mouseenter', '.map-navigation ul>li', mapNavHover);
            $b.on('mouseleave', '.map-navigation ul>li', mapNavLeave);

            $b.on('click', '.map-dot-wrapper', mapDotClick);
            $b.on('mouseenter', '.map-navigation ul>li', mapNavClick);

            $b.on('click', '.show-map-category-mobile', function (e) {
                e.preventDefault();
                $mapSidebar.addClass('show');
            });
            $b.on('click', '.hide-map-category-mobile', function (e) {
                e.preventDefault();
                $mapSidebar.removeClass('show');
            });
        },

        /*
        * Init Draggable Map
        * */

        draggableMap: function (map) {
            var sidebarWidth = 370,
                sidebarHeight = 0;

            if($w.width() < 992) {
                sidebarWidth = 0;
                sidebarHeight = 62;
            } else {
                sidebarWidth = 370;
                sidebarHeight = 0;
            }

            $w.resize(function () {
                if($w.width() < 992) {
                    sidebarWidth = 0;
                    sidebarHeight = 62;
                } else {
                    sidebarWidth = 370;
                    sidebarHeight = 0;
                }
            });

            map.draggable({
                drag: function(event, el) {
                    var pos = el.position,
                        mapHeight = map.height(),
                        pageHeight = $w.height() - sidebarHeight,
                        x = pos.left,
                        y = pos.top;

                    if (pos.top > 0) {
                        y = 0;
                    } else {
                        if (pos.top + mapHeight < pageHeight) {
                            y = pageHeight - mapHeight
                        }
                    }

                    var mapWidth = map.width(),
                        pageWidth = $w.width() - sidebarWidth;

                    if (pos.left > 0 || mapWidth < pageWidth) {
                        x = 0
                    } else {
                        if (pos.left + mapWidth < pageWidth) {
                            x = pageWidth - mapWidth;
                        }
                    }

                    $mapOuter.css({'transform' : 'translateX('+ x +'px) translateY('+ y +'px)'});
                },
                start: function () {
                    $mapOuter.addClass('no-anim');
                },
                stop: function () {
                    $mapOuter.removeClass('no-anim');
                }
            });
        }
    };

    CF.documentOnReady = {
        init: function() {
            CF.initialize.init();
            CF.documentOnReady.onReady();
        },
        onReady: function() {

        }
    };

    CF.documentOnLoad = {
        init: function() {
            $w.on('load', function(){

            });
        }
    };

    CF.documentOnResize = {
        init: function() {
            $w.resize(function() {

            });
        }
    };

    var $w = $(window),
        $d = $(document),
        $h = $('html'),
        $b = $('body'),
        $mapNavigation = $('.map-navigation'),
        $mapDots = $('.map-dots'),
        $mapContainer = $('.map-container'),
        $mapOuter = $('.map-outer'),
        $mapSidebar = $('.map-sidebar');

    $d.ready(CF.documentOnReady.init());

})(jQuery);
